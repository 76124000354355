<script lang='ts' setup>
const { t: $t } = useI18n()

import type { DropdownOption } from 'naive-ui'

const { username } = useAuthorization()

const options = computed((): DropdownOption[] => ([
  {
    label: $t('customerModule.breadcrumb.my-hos'),
    key: 'toApp',
    icon: () => useRenderIcon({ name: 'material-symbols:open-in-new-rounded' }),
  },
  {
    label: $t('customerModule.navigation.shop.title'),
    key: 'toShop',
    icon: () => useRenderIcon({ name: 'material-symbols:shopping-cart-outline-rounded' }),
  },
  { key: 'div1', type: 'divider' },
  {
    label: $t('customerModule.navigation.logout.title'),
    key: 'logout',
    icon: () => useRenderIcon({ name: 'material-symbols:logout-rounded' }),
  },
]))

function onClick(key: string) {
  if (key === 'toApp') {
    navigateTo('/my-hos')
  }
  if (key === 'toShop') {
    navigateTo('/shop')
  }
  if (key === 'logout') {
    navigateTo('/auth/logout')
  }
}
</script>

<template>
  <n-dropdown
    trigger="hover"
    placement="bottom-end"
    :width="175"
    :options="options"
    @select="onClick"
  >
    <n-button type="primary" secondary color="white" icon-placement="right">
      <template #icon>
        <Icon name="material-symbols:keyboard-arrow-down-rounded" class="text-white" />
      </template>
      {{ username }}
    </n-button>
  </n-dropdown>
</template>
