<script lang="ts" setup>
import { useRouteQuery } from '@vueuse/router'

withDefaults(defineProps<{ displayText?: boolean }>(), { displayText: true })

const routeQuery = useRouteQuery('lang')

const i18n = useI18n()
const { availableLocales } = useCustomerModuleLocale()

const options = availableLocales.map(lang => ({
  label: lang.name,
  value: lang.code,
}))

const locale = ref(typeof routeQuery.value === 'string' ? routeQuery.value : i18n.locale.value)
watch(locale, (newLocale) => {
  if (i18n.locale.value !== newLocale && i18n.locales.value.some(l => l.code === newLocale)) {
    i18n.setLocale(newLocale)
    routeQuery.value = newLocale
  }
}, { immediate: true })

function getLocaleIcon(locale?: string | null) {
  if (locale === 'en') {
    return 'cif:gb'
  }
  return 'cif:de'
}
</script>

<template>
  <n-popselect v-model:value="locale" :options="options" placement="top-start">
    <n-button type="primary" secondary color="white">
      <template #icon>
        <Icon :name="getLocaleIcon(locale)" class="rounded" />
      </template>
      <template v-if="displayText">
        {{ i18n.localeProperties.value.name ?? locale }}
      </template>
    </n-button>
  </n-popselect>
</template>
